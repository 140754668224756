import React from "react";
import { DefaultAppBar, DefaultAppBarProps, useApp, useLargeLayout, useNavigationController } from "@firecms/core";
import { ArrowBackIcon, Button, ForumIcon, LogoutIcon, MenuItem, PaymentIcon, Tooltip } from "@firecms/ui";

import { ProjectsSelect } from "./ProjectsSelect";
import { useSaasClientController } from "../SaasApp";
import { Link, useNavigate } from "react-router-dom";
import { useDataTalkMode } from "@firecms/cloud";
import { useInProject } from "../hooks/useInProject";

export const SaasCMSAppBar = function SaasCMSAppBar({
                                                        title,
                                                        includeProjectSelect = true,
                                                    }: DefaultAppBarProps & {
    includeProjectSelect?: boolean;
}) {

    const navigation = useNavigationController();
    const navigate = useNavigate();
    const {
        fireCMSBackend,
        goToSubscriptions
    } = useSaasClientController();

    const drawer = useApp();
    const largeLayout = useLargeLayout();
    const dataTalkMode = useDataTalkMode();
    const inProject = useInProject();

    return <DefaultAppBar title={title}
                          user={fireCMSBackend?.user ?? undefined}
                          endAdornment={<>

                              {inProject &&
                                  <> {dataTalkMode &&
                                      <Tooltip title={"Back to CMS"}>
                                          <Link to={navigation.homeUrl}>
                                              <Button variant={"text"}><ArrowBackIcon/></Button>
                                          </Link>
                                      </Tooltip>
                                  }

                                      <Tooltip title={"DATATALK"}>
                                          <Link to={"datatalk"}>
                                              <Button variant={"outlined"}><ForumIcon size="small"/></Button>
                                          </Link>
                                      </Tooltip>
                                  </>}


                              {includeProjectSelect && <ProjectsSelect key={"project_select"}/>}
                          </>}
                          dropDownActions={
                              <>
                                  <MenuItem onClick={goToSubscriptions}>
                                      <PaymentIcon size="small"/> Subscriptions
                                  </MenuItem>
                                  <MenuItem onClick={() => {
                                      console.log("Signing out");
                                      fireCMSBackend?.signOut();
                                      navigate("/", { replace: true });
                                  }}>
                                      <LogoutIcon size="small"/>
                                      Logout
                                  </MenuItem>
                              </>
                          }/>
}
